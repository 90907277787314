import styled from 'styled-components';

export const ShareCss = styled.nav`
  height: 80px;
  padding-bottom: 35px;
  margin-bottom: 18px;
  display: flex;
  gap: 6px;
  justify-content: space-between;
  a,
  button,
  span {
    display: inline-block;
    text-decoration: none;
    border: none;
    outline: none;
    height: 24px;
    width: 24px;
    padding: 0;
    font-size: 18px;
    color: white;
    display: flex;
    transition: 0.3s;
    justify-content: center;
    align-items: center;
    &:hover {
      transform: scale(1.1);
    }
  }
  .facebook {
    font-size: 18px;
    background-color: #3c5b9b;
  }
  .twitter {
    background-color: #2daae1;
  }
  .linkedIn {
    background-color: #0077b5;
  }
  .email {
    background-color: #e52d27;
  }
  .print {
    background-color: #738a8d;
  }
  .wechat {
    background-color: #00c80f;
    cursor: pointer;
  }
`;
